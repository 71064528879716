<template>
  <div>
    <div
      v-if="currentPage === 1"
      class="mt-10 text-center"
      style="color: rgba(69, 121, 223, 1); font-weight: bold; font-size: 24px"
    >
      <div>
        รายงานแผนที่สัดส่วนแสดงเป็นจำนวนผู้ถูกกระทำเทียบกับจำนวนประชากรแต่ละพื้นที่
      </div>
    </div>
    <div
      v-if="currentPage === 2"
      class="mt-10 text-center"
      style="color: rgb(8, 146, 146); font-weight: bold; font-size: 24px"
    >
      <div>
        รายงานแผนที่สัดส่วนแสดงเป็นจำนวนผู้ถูกกระทำเทียบกับจำนวนประชากรแต่ละพื้นที่
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <b-button-group class="bg-grey-theme w-50">
        <b-button
          @click="clickPage(item)"
          v-for="item in buttonList"
          :key="item.id"
          :class="
            item.isActive
              ? 'btn-page-violence px-5'
              : 'btn-no-page-violence px-5'
          "
          >{{ item.name }}</b-button
        >
      </b-button-group>
    </div>
    <div class="container mb-5 mt-3" style="max-width: 1200px">
      <div>
        <b-row>
          <b-col md="4"></b-col>
          <b-col md="2">
            <span>เขตสุขภาพ</span>
            <div>
              <b-form-select
                style="border-radius: 20px"
                v-model="selected_area"
                v-on:change="changeArea"
                :options="optionsarea || []"
                size="sm"
                class="mt-3"
              >
              </b-form-select>
            </div>
          </b-col>
          <b-col md="2">
            <span>จังหวัด</span>
            <div>
              <b-form-select
                text-field="name"
                value-field="code"
                style="border-radius: 20px"
                v-model="selected_province"
                :options="optionsprovice || []"
                size="sm"
                class="mt-3"
              >
              </b-form-select>
            </div>
          </b-col>
          <b-col md="2">
            <span>ปี พ.ศ.</span>
            <div>
              <b-form-select
                style="border-radius: 20px"
                v-model="selected_year"
                :options="options || []"
                size="sm"
                class="mt-3"
              >
              </b-form-select>
            </div>
          </b-col>
          <b-col md="2" class="pt-7">
            <div>
              <b-button
                style="border-radius: 20px"
                variant="primary"
                @click="changeYear"
                >แสดงข้อมูล</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="content-graph-oscc mt-5">
        <Map1
          :src_map="src_map1"
          :map="map1"
          :province="province_check"
          v-if="currentPage === 1"
          @submit="getProvince"
        ></Map1>
        <Map2
          :src_map="src_map2"
          :map="map2"
          :province="province_check"
          v-if="currentPage === 2"
          @submit="getProvince"
        ></Map2>
      </div>
    </div>
  </div>
</template>

<script>
import Map1 from "../components/Map1";
import Map2 from "../components/Map2";
import { domain } from "../util/Constants";

export default {
  name: "ReportSummaryMap",
  components: { Map2, Map1 },
  data() {
    return {
      buttonList: [
        { id: 1, name: "ข้อมูลผู้ที่ถูกกระทำรุนแรง", isActive: true },
        { id: 2, name: "ข้อมูลผู้ที่ตั้งครรภ์ไม่พึงประสงค์", isActive: false },
      ],
      currentPage: 1,
      src_map1: "",
      map1: [],
      src_map2: "",
      map2: [],
      selected_year: "",
      selected_province: "",
      selected_area: "",
      optionsprovice: [],
      optionsarea: [
        { text: "ทุกเขตสุขภาพ", value: "" },
        { text: "เขตสุขภาพที่ 1", value: "1" },
        { text: "เขตสุขภาพที่ 2", value: "2" },
        { text: "เขตสุขภาพที่ 3", value: "3" },
        { text: "เขตสุขภาพที่ 4", value: "4" },
        { text: "เขตสุขภาพที่ 5", value: "5" },
        { text: "เขตสุขภาพที่ 6", value: "6" },
        { text: "เขตสุขภาพที่ 7", value: "7" },
        { text: "เขตสุขภาพที่ 8", value: "8" },
        { text: "เขตสุขภาพที่ 9", value: "9" },
        { text: "เขตสุขภาพที่ 10", value: "10" },
        { text: "เขตสุขภาพที่ 11", value: "11" },
        { text: "เขตสุขภาพที่ 12", value: "12" },
        { text: "เขตสุขภาพที่ 13", value: "13" },
      ],
      options: [],
      one_time: false,
      province_check: false,
    };
  },
  created() {
    this.showLoading();
    const page = this.$route.query.id ? this.$route.query.id : 1;
    this.currentPage = parseInt(page);
    this.$store.dispatch("MasterData/setProvince").then((res) => {
      this.optionsprovice = res.data;
      this.optionsprovice = [
        { name: "ทุกจังหวัด", code: "" },
        ...this.optionsprovice,
      ];
      if (this.currentPage === 1) {
        this.$store.dispatch("dashboard/get_year_violence").then((res) => {
          this.options = res.data.data;
          this.$forceUpdate();
          this.hideLoading();
        });
      }
      if (this.currentPage === 2) {
        this.$store.dispatch("dashboard/get_year_pregnant").then((res) => {
          this.options = res.data.data;
          this.$forceUpdate();
          this.hideLoading();
        });
      }
    });
    this.buttonList.forEach((x) => {
      if (x.id === this.currentPage) x.isActive = true;
      else x.isActive = false;
    });
  },
  methods: {
    clickPage(model) {
      this.$router.push({
        path: "report-summary-map",
        query: { id: model.id },
      });
    },
    fillDataMap1() {
      this.selected_year = this.selected_year ? this.selected_year : "";
      console.log("fill");
      this.$store
        .dispatch("dashboard/map_1", {
          year: this.selected_year,
          area: this.selected_area,
          province: this.selected_province,
        })
        .then((res) => {
          this.map1 = res.data.data;
          this.src_map1 =
            domain.slice(0, -4) +
            "/map_thai1?year=" +
            this.selected_year +
            "&area=" +
            this.selected_area +
            "&province=" +
            this.selected_province;

          console.log(this.src_map1);
          this.hideLoading();
        });
    },
    fillDataMap2() {
      this.selected_year = this.selected_year ? this.selected_year : "";
      this.$store
        .dispatch("dashboard/map_2", {
          year: this.selected_year,
          area: this.selected_area,
          province: this.selected_province,
        })
        .then((res) => {
          this.map2 = res.data.data;
          this.src_map2 =
            domain.slice(0, -4) +
            "/map_thai2?year=" +
            this.selected_year +
            "&area=" +
            this.selected_area +
            "&province=" +
            this.selected_province;
          this.hideLoading();
        });
    },
    changeArea() {
      this.showLoading();
      this.$store.dispatch("MasterData/setProvince").then((res) => {
        this.optionsprovice = res.data;
        if (this.selected_area == "") {
          this.optionsprovice = [
            { name: "ทุกจังหวัด", code: "" },
            ...this.optionsprovice,
          ];
        } else {
          this.optionsprovice = [
            { name: "ทุกจังหวัด", code: "" },
            ...this.optionsprovice.filter(
              (data) => this.selected_area == data.area_code
            ),
          ];
        }

        this.selected_province = "";
        this.hideLoading();
      });
    },
    changeYear() {
      this.showLoading();
      this.province_check = false;
      if (this.currentPage === 1) this.fillDataMap1();
      if (this.currentPage === 2) this.fillDataMap2();
    },
    getProvince(name) {
      this.showLoading();
      if (this.currentPage === 1) {
        this.$store
          .dispatch("dashboard/map_province_1", {
            year: this.selected_year,
            province: name,
          })
          .then((res) => {
            this.province_check = true;
            this.map1 = res;
            this.src_map1 =
              domain.slice(0, -4) +
              "/map_province_1?year=" +
              this.selected_year +
              "&PROV_CODE=" +
              name;

            this.hideLoading();
          });
      }
      if (this.currentPage === 2) {
        this.$store
          .dispatch("dashboard/map_province_2", {
            year: this.selected_year,
            province: name,
          })
          .then((res) => {
            this.province_check = true;
            this.map2 = res;
            this.src_map2 =
              domain.slice(0, -4) +
              "/map_province_2?year=" +
              this.selected_year +
              "&PROV_CODE=" +
              name;
            this.hideLoading();
          });
      }
    },
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
  },
};
</script>

<style>
.content-graph-oscc {
  padding-top: 2%;
  padding-bottom: 2%;
  background: #ffffff;
  border: 0.5px solid #a8a8a8;
  box-sizing: border-box;
  box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
  border-radius: 5px;
}
.content-graph-oscc span.head-graph-oscc {
  font-weight: bold;
  font-size: 20px;
}
</style>
